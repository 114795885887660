import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import i18next from '../../config/i18next';
import { isChinaUser } from '../../config/isChina';
import Translate from '../../components/Translate';
import Button from '../../components/button';
import Layout from '../../components/layout';
import GetAppsSection from '../../components/getAppsSection';
import AppleGoogleStores from '../../components/appleGoogleStores';
import computerIcon from '../../data/images/SVG-files/icon-computer.svg';
import vivofitAppIcon from '../../data/images/app-icon-vvfj.png';
import golfAppIcon from '../../data/images/app-icon-golf.png';
import diveAppIcon from '../../data/images/dive-app.png';
import connectIQAppIcon from '../../data/images/connectiq-app.png';
import indexSmartScaleIcon from '../../data/images/index-smart-scale.png';
import vivokiIcon from '../../data/images/vivoki.png';
import quickdrawCommunityIcon from '../../data/images/quickdraw-community.png';
import shopDevices from '../../data/images/index/GCE_Device_Strip_update.png';

const tempStrings = {
    vivoki: 'vívokí',
    indexSmartScale: 'Index Smart Scale',
    quickdrawCommunity: 'Quickdraw Community'
};

const styles = {
    landingSection: {
        display: 'flex',
        backgroundColor: '#fff',
        flexDirection: 'column',
        borderBottom: '1px solid #eeeeee',
        height: '90vh',
        minHeight: '800px',
        '& img': {
            width: '100%',
            height: 'auto',
            maxHeight: '450px' // IE fix to render the image right
        }
    },
    landingText: {
        flexDirection: 'column',
        alignSelf: 'center',
        width: '35%',
        paddingTop: '30px',
        display: 'flex',
        textAlign: 'center',
        '& > div:nth-of-type(1)': {
            fontSize: '2.4rem',
            marginBottom: '25px',
            lineHeight: '40px',
            fontWeight: '500'
        },
        '& > div:nth-of-type(2)': {
            fontSize: '1.2rem',
            fontWeight: '300'
        }
    },
    appSection: {
        display: 'inline-flex',
        margin: '30px 0 30px 0',
        alignSelf: 'center',
        '& > div': {
            margin: '0 10px 0 0'
        }
    },
    header: {
        fontSize: '2.4rem',
        lineHeight: '40px',
        fontWeight: '500',
        textAlign: 'center'
    },
    content: {
        fontSize: '1.2rem',
        fontWeight: '300',
        marginBottom: '20px',
        textAlign: 'center',
        padding: '10px'
    },
    /* ----------------------------  'Get Garmin Express' section ------------------------------------ */
    getGarminExpress: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        background: '#fff',
        borderBottom: '1px solid #eeeeee',
        minHeight: '450px'
    },
    getGarminExpressText: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '50%'
    },
    /* ----------------------------  'Device selection' section ------------------------------------ */
    selectDevices: {
        height: '50vh',
        minHeight: '450px',
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        '& > div': {
            display: 'inline-flex'
        },
        '& a': {
            padding: '0 10px 0 10px'
        }
    },
    icons: {
        width: '180px',
        height: '180px'
    },
    '@media only screen and (max-width: 767px)': {
        landingSection: {
            textAlign: 'center'
        },
        landingText: {
            alignItems: 'center',
            margin: 0,
            width: '90%',
            padding: 0,
            '& > div:nth-of-type(1)': {
                fontSize: '2rem',
                marginBottom: '30px',
                lineHeight: '40px',
                width: '100%'
            },
            '& > div:nth-of-type(2)': {
                width: '100%',
                fontSize: '1.2rem',
                fontWeight: '300'
            }
        },
        appSection: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: '0 0 20px 0',
                alignSelf: 'center'
            }
        },
        getGarminExpress: {
            padding: '40px 10px 40px 10px'
        },

        getGarminExpressText: {
            width: '100%'
        },
        header: {
            fontSize: '2rem',
            fontWeight: '400'
        },
        selectDevices: {
            height: 'fit-content',
            padding: '40px 10px 40px 10px',
            '& > div': {
                display: 'flex',
                flexDirection: 'column'
            }
        }
    }
};

class StartPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            updateAndroidLink: false
        };
    }

    componentDidMount() {
        this.loadTitleTranslation();
        this.setState({ updateAndroidLink: isChinaUser() });
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:get_started`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    renderAppendedChildren = () => {
        const { classes } = this.props;
        const garminExpressUrl = this.state.updateAndroidLink
            ? 'https://www.garmin.com.cn/products/apps/garmin-express/'
            : 'https://www.garmin.com/en-US/software/express/';
        const renderVivoki = this.state.updateAndroidLink ? (
            ''
        ) : (
            <Link to="/start/vivoki">
                <img src={vivokiIcon} className={classes.icons} alt="" />
                <p>{tempStrings.vivoki}</p>
            </Link>
        );
        const golfApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmingolfapp/'
                : 'https://buy.garmin.com/en-US/US/p/590068',
            image: golfAppIcon,
            title: 'Garmin Golf'
        };
        const vivofitApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmin_jr_app/'
                : 'https://buy.garmin.com/en-US/US/p/568169',
            image: vivofitAppIcon,
            title: 'Garmin Jr.'
        };
        const connectQIApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/connect-iq/'
                : 'https://buy.garmin.com/en-US/US/p/616398',
            image: connectIQAppIcon,
            title: 'Connect IQ Store'
        };
        const diveApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmin-dive-app/'
                : 'https://buy.garmin.com/en-US/US/p/707742',
            image: diveAppIcon,
            title: 'Garmin Dive'
        };

        return (
            <aside>
                {/* ----------------------------  'Get Garmin Express' section ------------------------------------ */}
                <div className={classes.getGarminExpress}>
                    <img className={classes.icons} src={computerIcon} alt="" />
                    <div className={classes.getGarminExpressText}>
                        <div className={classes.header}>
                            {<Translate ns="prelogin_pages" content="dont_have_a_smartphone" />}
                        </div>
                        <div className={classes.content}>
                            {<Translate ns="prelogin_pages" content="dont_have_a_smartphone_content" />}
                        </div>
                        <Button size="large">
                            <a href={garminExpressUrl}>
                                {<Translate ns="prelogin_pages" content="get_garmin_express" />}
                            </a>
                        </Button>
                    </div>
                </div>
                {/* ----------------------------  'Device selection' section ------------------------------------ */}
                <div className={classes.selectDevices}>
                    <div className={classes.header}>{<Translate ns="prelogin_pages" content="select_devices" />}</div>
                    <div className={classes.content}>
                        {<Translate ns="prelogin_pages" content="select_devices_content" />}
                    </div>
                    <div>
                        <Link to="/start/index-scale/mobile">
                            <img src={indexSmartScaleIcon} className={classes.icons} alt="" />
                            <p>{tempStrings.indexSmartScale}</p>
                        </Link>
                        {renderVivoki}
                        <Link to="/start/quickdraw">
                            <img src={quickdrawCommunityIcon} className={classes.icons} alt="" />
                            <p>{tempStrings.quickdrawCommunity}</p>
                        </Link>
                    </div>
                </div>
                {/* ----------------------------  'Other Garmin Connect Apps' section ------------------------------------ */}
                <GetAppsSection appList={[connectQIApp, diveApp, golfApp, vivofitApp]} />
            </aside>
        );
    };

    render() {
        const { classes } = this.props;
        const title = this.state.title;

        return (
            <Layout appendChildren={this.renderAppendedChildren()}>
                {/* ------------------------------    Set up device ---------------------------------------- */}
                <div className={classes.landingSection}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta name="title" content="Garmin Connect: Get Started" />
                        <meta
                            name="description"
                            content="It only takes a few steps to set up your Garmin device for Garmin Connect."
                        />
                        <meta
                            name="keywords"
                            content="garmin connect, garmin, connect, get started, start, sync, garmin express, express, download, upload, activities, fitness"
                        />
                    </Helmet>
                    <div>
                        <img src={shopDevices} alt="" />
                    </div>
                    <div className={classes.landingText}>
                        <div>
                            <Translate ns="prelogin_pages" content="set_up_device" />
                        </div>
                        <div>
                            <Translate ns="prelogin_pages" content="set_up_device_content" />
                        </div>
                        <div className={classes.appSection}>
                            <AppleGoogleStores />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(StartPage);
